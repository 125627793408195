<template>
  <div
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <menu-ver
      type="profesor"
      :content="courses"
      :id_emp="id_emp"
      v-on:theme="selectTheme"
    />
    <div class="container m-3">
      <div
        :class="{
          tableheader: !device,
          tableheadermobile: device,
        }"
      >
        <h1 v-if="activeCourse">
          {{ $t("mUser.courses.titleProfesor3") }}
        </h1>
        <table class="table" v-if="activeCourse">
          <thead>
            <tr>
              <th scope="col">{{ $t("maintenance.languages.key") }}</th>
              <th scope="col">{{ $t("maintenance.languages.name") }}</th>
              <th scope="col">{{ $t("maintenance.courses.themes") }}</th>
              <th scope="col">{{ $t("maintenance.courses.students") }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in course" :key="item.curso_id">
              <td>{{ item.curso_id }}</td>
              <td>{{ item.curso_titulo }}</td>
              <td>{{ item.curso_temas }}</td>
              <td>{{ item.curso_alumnos }}</td>
              <td style="min-width: 150pt">
                <button
                  class="btn button buttonDelete ms-2"
                  @click="reqPublication(item.curso_id)"
                  :data-title="$t(`profesor.solicitud`)"
                >
                  <i class="fas fa-globe"></i>
                </button>
                <button
                  class="btn button buttonDelete ms-2"
                  @click="seeTemas(item.curso_id)"
                  :data-title="$t(`menu.vertemas`)"
                >
                  <i class="fas fa-list"></i>
                </button>
                <button
                  class="btn button buttonEdit ms-2"
                  @click="seeMaterial(item.curso_id)"
                  :data-title="$t(`menu.vermaterial`)"
                >
                  <i class="fas fa-file"></i>
                </button>
                <button
                  class="btn button buttonEdit"
                  @click="seeCurse(item.curso_id)"
                  :data-title="$t(`menu.vercurso`)"
                >
                  <i class="far fa-eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 v-if="messages && messages.length > 0">
          {{ $t("mUser.courses.titleProfesor") }}
        </h1>
        <h1 v-if="students && students.length > 0">
          {{ $t("mUser.courses.titleProfesor2") }}
        </h1>

        <button
          v-if="messages && messages.length > 0"
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          style="width: 140pt; background-color: crimson; color: white"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.sendMessage") }}
        </button>
      </div>
      <table class="table" v-if="students && students.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in students" :key="item.usu_id">
            <td>{{ item.usu_nombre }}</td>
            <td>
              <button class="btn button buttonEdit" @click="getMessages(item)">
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <h1 v-if="works && works.length > 0">
        {{ $t("mUser.courses.titleProfesor4") }}
      </h1>
      <table class="table" v-if="works && works.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col">{{ $t("mUser.courses.ncourse") }}</th>
            <th scope="col">{{ $t("mUser.courses.nblock") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in works" :key="item.usu_id">
            <td>{{ item.usu_nombre }}</td>
            <td>{{ item.bloque_titulo }}</td>
            <td>{{ item.curso_titulo }}</td>
            <td>
              <button
                class="btn button buttonEdit"
                @click="viewWork(item)"
              >
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table" v-if="messages && messages.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("phases.date") }}</th>
            <th scope="col">{{ $t("mUser.course.mensaje") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in messages" :key="item.mecu_id">
            <td>{{ item.mecu_fecha }}</td>
            <td>{{ item.mecu_texto }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("profesor.titleAdd") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.course.mensaje`) + " *"
              }}</label>
              <textarea
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormRol"
                v-model.trim="mecu_texto"
                rows="8"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="sendMessage"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal work -->
  <div
    class="modal fade"
    id="workModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("profesor.revWork") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormsWork()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3" style="font-size: 18pt">
              {{ work.usu_nombre }}
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.blocks.workStud`)
              }}</label>
              <a
                :href="downloadFile"
                target="_blank"
                class="button"
                style="width: 100%"
                :style="{ backgroundColor: colorCompany }"
                ><i class="fas fa-download"></i
              ></a>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`profesor.note`)
              }}</label>
              <input
                type="number"
                class="form-control"
                v-model.trim="enbloque_puntos"
                :placeholder="$t(`profesor.note`)"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="callWork"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanFormsWork()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <!-- <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.courses.editTitle", { name: `${courseId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormsEdit()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="curso_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="curso_email"
                :placeholder="$t(`maintenance.superusers.email`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="curso_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>

            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.iWeb") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="curso_web"
                id="flexCheckDefault"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <div v-if="curso_web">
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.category`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_categoria_cd"
                >
                  <option
                    v-for="cate in categorias"
                    :key="cate.cate_id"
                    :value="cate.cate_id"
                  >
                    {{ cate.cate_nombre_ESP }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.duration`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_duracion"
                  :placeholder="$t(`mUser.courses.duration`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.price`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  v-model.trim="curso_precio"
                  :placeholder="$t(`mUser.courses.price`)"
                  maxlength="50"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.description`)
                }}</label>
                <textarea
                  v-model.trim="curso_descripcion"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.descriptionL`)
                }}</label>
                <textarea
                  v-model.trim="curso_desc_larga"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.courses.knowledge`)
                }}</label>
                <textarea
                  v-model.trim="curso_conocimientos_previos"
                  class="form-control"
                  rows="7"
                  style="text-transform: uppercase"
                  maxlength="500"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{ $t(`languages.title`) }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="curso_idioma"
                >
                  <option value="ESP">{{ $t(`languages.es`) }}</option>
                  <option value="ENG">{{ $t(`languages.en`) }}</option>
                </select>
              </div>
            </div>

            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editCourse"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanFormsEdit()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="result === '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#profesorModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response profesor public-->
  <div
    class="modal fade"
    id="resProfeModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="1exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.prof0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.prof1") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      courses: [],
      messages: [],
      students: [],
      works: [],
      work: {},
      id_emp: this.$route.params.idemp,
      mecu_texto: "",
      selectedTheme: {},
      formError: false,
      alumno_cd: "",
      result: "",
      curso_titulo: "",
      curso_email: "",
      curso_clave: "",
      curso_id: "",
      curso_temas: "",
      curso_alumnos: "",
      courseId: "",
      curso_web: 0,
      curso_categoria_cd: 0,
      curso_precio: "",
      curso_duracion: "",
      curso_descripcion: "",
      curso_desc_larga: "",
      curso_conocimientos_previos: "",
      curso_idioma: "ESP",
      categorias: [],
      activeCourse: false,
      visPas: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      course: [],
      editcourse: false,
      messageError: "",
      empresa_id: "",
      curso_actual: "",
      downloadFile: "",
      enbloque_puntos: ""
    };
  },
  created() {
    this.getcourses();
    this.changeBreadCrumb(23);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor", "colorCompany", "device", "professorId"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado`
        );
        // console.log("miscursos", res);
        if (res.data) {
          this.courses = res.data.data;
        }
        if (this.professorId !== null) {
          this.activeCourse = true;
          this.course.push(this.courses[this.professorId]);
          // console.log("prof", this.professorId);
          this.selectedTheme = this.courses[this.professorId];
          // console.log("selThemEND", this.selectedTheme);
          this.getStudents();
          this.getWorks();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMessages(item) {
      // console.log("Alumno", item);
      if (item && item.usu_id) {
        this.alumno_cd = item.usu_id;
        this.empresa_id = item.usu_empresa_cd;
      }
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listadoMensajes&curso=${this.selectedTheme.curso_id}&alumno=${this.alumno_cd}`
        );
        // console.log("recMensajes", res);
        if (res.data) {
          this.students = [];
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getStudents() {
      // console.log("selTheme", this.selectedTheme);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listadoCurso&curso=${this.selectedTheme.curso_id}`
        );
        // console.log("misalumnos", res);
        if (res.data) {
          this.students = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getWorks() {
      // console.log("selTheme", this.selectedTheme);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=trabajos&curso=${this.selectedTheme.curso_id}`
        );
        // console.log("works", res);
        if (res.data) {
          this.works = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    viewWork(item) {
      this.work = item;
      this.enbloque_id = item.enbloque_id;
      this.downloadFile = "";
      $("#workModal").modal("show");
    },
    async callWork() {
      //const res = await axios.post();
    },
    async sendMessage() {
      try {
        const message = {
          mecu_texto: this.mecu_texto.toUpperCase(),
          mecu_curso_cd: this.selectedTheme.curso_id,
          mecu_alumno_cd: this.alumno_cd,
          empresa_id: this.empresa_id,
        };
        // console.log("mensaje", message);
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=grabar`,
          message
        );
        // console.log("mismensajes", res);
        if (res.data) {
          this.result = res.data.RESULT;
        } else {
          this.result = "1";
        }
      } catch (error) {
        console.error(error);
      }
      $("#addModal").modal("hide");
      $("#responseModal").modal("show");
    },
    validateForm() {
      if (!this.validatePassword()) {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.mecu_texto = "";
      this.formError = false;
      this.getMessages();
    },
    selectTheme(item) {
      this.messages = [];
      this.selectedTheme = item;
      this.getStudents();
      this.getWorks();
      this.activeCourse = true;
      this.course = [];
      this.course.push(item);
      //this.selectCourse(item);
    },
    cleanFormsWork() {
      this.work = null;
      this.enbloque_puntos = "";
    },
    seeTemas(id) {
      this.$router.push({
        path: `/profesor/theme/${id}`,
      });
    },
    seeMaterial(id) {
      this.$router.push({
        path: `/profesor/material/${id}`,
      });
    },
    seeCurse(id) {
      let routeUrl = this.$router.resolve({
        path: `/profesor/course/${id}`,
      });
      window.open(routeUrl.href, "_blank");
    },
    async reqPublication(id) {
      try {
        $("#resProfeModal").modal("show");
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=solicitar_publi&curso=${id}`
        );
        // console.log("res", res);
        if (res.data) {
          this.result = res.data.RESULT;
        } else {
          this.result = "1";
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.btn {
  float: right;
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>